@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Literata';
  src: url('/fonts/Literata/Literata12ptBold.ttf');
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: 'Literata';
  src: url('/fonts/Literata/Literata12ptBold.ttf');
  font-style: normal;
  font-weight: 700;
  font-display: swap;
}
@font-face {
  font-family: 'SFProDisplay';
  src: url('/fonts/SFProDisplay/FontsFreeNetSFProDisplayRegular.ttf');
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: 'SFProDisplay';
  src: url('/fonts/SFProDisplay/FontsFreeNetSFProDisplayBold.ttf');
  font-style: normal;
  font-weight: 700;
  font-display: swap;
}

.swiper-pagination-bullet {
  opacity: 1 !important;
  width: 0.75rem !important;
  height: 0.75rem !important;
  background: #ffffff !important;
}

.swiper-pagination-bullet-active {
  background-color: #5d31ff !important;
  opacity: 1;
}

html {
  scroll-behavior: smooth;
}
